import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {

  constructor(private http: HttpClient) { }
  downloadFile(file: string){
    console.log(file)
   return this.http.post('./', file)
  }
}
