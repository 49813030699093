import { DatePipe, formatNumber } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, LOCALE_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { FileServiceService } from '../file-service.service';
@Component({
  selector: 'get-certificate',
  templateUrl: './get-certificate.component.html',
  styleUrls: ['./get-certificate.component.css']
})
export class GetCertificateComponent implements OnInit {
  form= new FormGroup({
    admNo: new FormControl('', Validators.required),
    dob: new FormControl(null, Validators.required)
  })
  tDate= new Date()
showError=false
  constructor(private fileSer: FileServiceService, private httpClient: HttpClient, private datePipe: DatePipe) { }

  ngOnInit() {
  }
  getFile(admno, dob){
    if(!this.form.get('admNo').valid && !this.form.get('dob').valid){
      alert('Please Fill Required Field')
      return
    }
    // this.fileExists('http://gyanbhartihisua.com/wp-content/uploads/2020/10/' + admno + this.datePipe.transform(dob, 'dd-MM-yyyy') +'.pdf').subscribe(res => {
    //   window.open('http://gyanbhartihisua.com/wp-content/uploads/2020/10/' + admno + this.datePipe.transform(dob, 'dd-MM-yyyy') +'.pdf')
    // },error => {
    //   this.showError=true
    // })
    // try{
    // window.open('http://gyanbhartihisua.com/wp-content/uploads/2020/10/' + admno + this.datePipe.transform(dob, 'dd-MM-yyyy') +'.pdf')

    // }catch{
    //         this.showError=true

    // }
    // var month= formatNumber(this.tDate.getUTCMonth() + 1, 'en-US', '2.0-0')
    if(this.doesFileExist('http://gyanbhartihisua.in/wp-content/uploads/' + admno + this.datePipe.transform(dob, 'dd-MM-yyyy') +'.pdf')){
         window.open('http://gyanbhartihisua.in/wp-content/uploads/' + admno + this.datePipe.transform(dob, 'dd-MM-yyyy') +'.pdf')
    }else{
      this.showError=true
    }
  }

  fileExists(url: string) {
    return this.httpClient.get(url, {observe: 'response', responseType: 'blob', headers:{'Access-Control-Allow-Origin':'*'} })
}

doesFileExist(urlToFile) {
  var xhr = new XMLHttpRequest();
  xhr.open('HEAD', urlToFile, true);
  xhr.send();

  if (xhr.status) {
      return false;
  } else {
      return true;
  }
}
}
