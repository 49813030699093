import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GetCertificateComponent } from './get-certificate/get-certificate.component';
import {HttpClientModule} from '@angular/common/http'
import { from } from 'rxjs';
import { FileServiceService } from './file-service.service';
import { DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    AppComponent,
    GetCertificateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [
    FileServiceService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
